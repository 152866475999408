module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gotham-teaser-gatsby","short_name":"gotham","start_url":"/","background_color":"##0005FF","theme_color":"##0005FF","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"58cbb5612082325679b0fc31b174695f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wordpress-547788-2960040.cloudwaysapps.com/graphql","type":{"MediaItem":{"localFile":{"excludeByMimeTypes":["video/mp4"],"maxFileSizeBytes":15728640,"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"Page":{"exclude":true},"Post":{"exclude":true},"Comment":{"exclude":true},"Author":{"exclude":true},"UserRole":{"exclude":true},"Category":{"exclude":true},"Taxonomy":{"exclude":true},"Tag":{"exclude":true},"PostFormat":{"exclude":true},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"User":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
